import { environment } from '../../../environments/environment'

import { Breakpoints } from './breakpoints'
import { Palette } from './palette'
import { TagSize } from './tag-size.interface'

export class ConstGlobal {

    static readonly ADVANCED_SETTINGS_MESSAGE: string = `These are advanced settings. Please contact Liquid <a href="mailto:${environment.internalEmailAdress.support}?subject=Help with onboarding process edits">${environment.internalEmailAdress.support}</a> for help if you would like to`
    static readonly CANNOT_BE_VERIFIED: string = `There are no steps that can be automatically validated. Please manually verify the process using the 'Edit' option, and make sure that all steps are completely filled out.`
    static readonly CONTRACT_END_DATE_WARNING: string = 'Warning: The end date for this contract is in the past. You will not be able to create Work Orders for this Vendor without an active Master Contract.'
    static readonly DEFAULT_ONBOARDING_PROCESS: string = 'Default Onboarding for Vendors'
    static readonly DEFAULT_PAGE_SIZE: number = 20
    static readonly EXPIRED_BUSINESS_TOKEN_MESSAGE: string = 'Your oToken has expired.'
    static readonly FILE_SIZE_32_MB: number = 33554432
    static readonly FILE_SIZE_50_MB: number = 52428800
    static readonly INVALID_PROMO_CODE: string = 'Promo Code Invalid'
    static readonly INVALID_SORT_PARAMETER: string = 'Invalid sort parameter'
    static readonly INVALID_TIME_TRACKER_ENTRY_TITLE: string = 'Invalid Time Tracking Data'
    static readonly INVALID_TIME_TRACKER_ENTRY: string = 'Please correct all issues or delete the invalid entry/entries.'
    static readonly KYC_TEXT: string = 'To ensure compliance with Know Your Customer (KYC) banking requirements, your account may be subject to business and personal identity verification. If so, you will be contacted by legal@poweredbyliquid.com and asked to provide supporting documentation and to conduct an identity verification. Once the KYC documentation has been provided, it can take up to 2 business days for review and acceptance. During that time, you will be unable to make invoice payments through Liquid. Please contact support@poweredbyliquid.com if you have any questions.'
    static readonly PERMISSION_DENIED_FINANCE: string = `You do not have permission to complete Finance Setup for this business.  Please contact a user with Administrator or Controller role to have them complete setup.`
    static readonly PROFILE_ID_IN_USE: string = `The Organization's short name is already in use.`
    static readonly REQUEST_CUSTOM_CONTRACTS: string = `Send your custom contract documents to Liquid at <a href='mailto:${environment.internalEmailAdress.onboardingSupport}'>${environment.internalEmailAdress.onboardingSupport}</a>. We will set up your onboarding process and let you know when it is ready.`
    static readonly UNDEFINED_INVOICE_ID: string = `Undefined invoice id`
    static readonly UNVERIFIED_ACCT_VERIFICATION_MESSAGE: string = 'Bank account verification takes 1-2 business days. You will be unable to send invoices or receive payment until your bank account is verified.'

    static readonly PALETTE: Palette = {
        primary: {
            p100: '#5A5C9B',
            p90: '#6B6DA5',
            p80: '#7B7DAF',
            p70: '#8C8DB9',
            p60: '#9C9DC3',
            p50: '#ADAECD',
            p40: '#BDBED7',
            p30: '#CECFE1',
            p20: '#DEDEEB',
            p10: '#EFEFF5',
        },
        black: {
            b100: '#25282B',
            b80: '#52575C',
            b60: '#A0A4A8',
            b40: '#CACCCF',
            b20: '#DBDDE0',
            b10: '#E8E8E8',
            b5: '#F7F7F7',
        },
        white: {
            default: '#FFFFFF',
            dark: '#F7F9FB',
        },
        warning: {
            default: '#FFE4A5',
            dark: '#CC8400',
            light: '#FFF2D2',
        },
        success: {
            default: '#2AC769',
            dark: '#128718',
            light: '#D1F9C6',
        },
        error: {
            default: '#FB4E4E',
            dark: '#E93C3C',
            light: '#FFC6C6',
        },
        accent: {
            default: '#01BCD5',
        },
    }

    static readonly BREAKPOINTS: Breakpoints = {
        xs: {
            min: 0,
            max: 599,
        },
        sm: {
            min: 600,
            max: 1023,
        },
        md: {
            min: 1024,
            max: 1439,
        },
        lg: {
            min: 1440,
            max: 1919,
        },
        xl: {
            min: 1920,
        },
    }

    static readonly TAG_SIZE: TagSize = {
        maxWidth: 115,
        groupWidth: 220,
        deleteWidth: 16,
        padding: 4,
        margin: 2,
        border: 1,
        fontSize: 10,
        fontFamily: 'Poppins, sans-serif',
    }
}
