import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { OrganizationMembershipAbstractMember } from '../../auth/models'
import {
    ClientVendorRelationship,
    ClientVendorRelationshipUpdatePropertiesRequest,
    LiquidDocument,
    LiquidDocumentUpdateRequest,
    LiquidDocumentUpdateRequestScope,
    OrganizationTeamInvitation,
    OrganizationTeamMemberUpdateRequest,
    TeamMemberAbstract,
    VendorInvitationUpdatePropertiesRequest,
} from '../../modules/models'
import { CountersigningStatus, HiringManagerScopeRequest, UpdatePropertiesRequestScope } from '../models'

import { BusinessUsersService } from './business-users.service'
import { ClientVendorRelationshipStore } from './client-vendor-relationship.store'
import { UserService } from './user.service'

@Injectable({
    providedIn: 'root',
})
export class ClientVendorRelationshipService {

    constructor(
        private bizUsers: BusinessUsersService,
        private store: ClientVendorRelationshipStore,
        private users: UserService,
    ) { }

    get(requesterOrganizationId: string, clientVendorRelationshipId: string): Observable<ClientVendorRelationship> {
        return this.store.getClientVendorRelationship(requesterOrganizationId, clientVendorRelationshipId)
            .pipe(
                take(1),
            )
    }

    getAllTeamMemberAbstracts(): Array<TeamMemberAbstract> {
        return this.users.teamConfigSnapshot.teamMembershipAbstract.teams[0].members
    }

    getTeamMemberAbstract(orgId: string): TeamMemberAbstract {
        return this.users.teamConfigSnapshot.teamMembershipAbstract.teams[0].members.find(m => m.teamMemberOrganization.teamMemberOrganizationId === orgId)
    }

    getHiringManager(info: {
        hiringManagerAvatarUrl?: string,
        hiringManagerName?: string,
        hiringManagerProfileId: string,
    }): OrganizationMembershipAbstractMember {

        // if the user exists for the biz, just return it
        const hiringManager: OrganizationMembershipAbstractMember = !!info ? this.bizUsers.businessUsers.find(u => u.profileId === info.hiringManagerProfileId) : undefined
        if (!!hiringManager) {
            return hiringManager
        }

        // create a placeholder for the user who no longer has permission
        const names: Array<string> = info?.hiringManagerName?.split(' ') || ['Unknown', '']
        const firstName: string = names[0]
        return {
            avatarUrl: info?.hiringManagerAvatarUrl,
            description: undefined,
            joinedOn: undefined,
            firstName: firstName,
            isMe: false,
            lastName: names.splice(1).join(' '),
            membershipId: undefined,
            permissions: [],
            profileId: info?.hiringManagerProfileId,
            roles: [],
        }
    }

    getId(clientId: string, vendorId: string): string {
        // if the current user is neither the vendor or the client, don't return any relationship
        return clientId !== this.users.businessSnapshot.id && vendorId !== this.users.businessSnapshot.id
            ? undefined
            : (
                clientId === this.users.businessSnapshot.id
                    ? this.users.teamConfigSnapshot.teamMembershipAbstract.teams
                        .reduce((agg, team) => [
                            ...agg,
                            ...team?.members,
                        ], [])
                        .find(v => v?.teamMemberOrganization?.teamMemberOrganizationId === vendorId)
                        ?.organizationTeamMemberId
                    : this.users.teamConfigSnapshot.organizationMembershipConfiguration.teamMemberships.find(i => i.membershipIsToOrganization?.id === clientId)?.id)
    }

    getDocuments(clientVendorRelationshipId: string): Observable<Array<LiquidDocument>> {
        return this.store.getDocumentsForClientVendorRelationship(clientVendorRelationshipId)
            .pipe(
                take(1),
            )
    }

    getDocumentsForCountersigning(clientVendorRelationshipId: string): Observable<Array<LiquidDocument>> {
        return this.store.getDocumentsForClientVendorRelationship(
            clientVendorRelationshipId,
            [CountersigningStatus.Required, CountersigningStatus.PendingAccepted, CountersigningStatus.PendingRejected],
        )
            .pipe(
                take(1),
            )
    }

    getMasterContract(clientVendorRelationshipId: string): Observable<LiquidDocument> {
        return this.store.getMasterContractDocumentForOrganizationTeamMember(clientVendorRelationshipId)
    }

    getMasterContracts(clientVendorRelationshipId: string): Observable<Array<LiquidDocument>> {
        return this.getDocuments(clientVendorRelationshipId)
            .pipe(
                map(docs => {
                    const output: Array<LiquidDocument> = docs
                        // only return visible master contracts
                        .filter(doc => doc.isMasterContractDocument && !doc.hiddenTimestamp && !doc.deletedTimestamp)
                        .sort((a, b) => {
                            // sort by start date, then by created date
                            const startDateSort: number = b.documentActiveFromTimestamp - a.documentActiveFromTimestamp
                            return startDateSort !== 0 ? startDateSort : b.createdTimestamp - a.createdTimestamp
                        })
                        .map(doc => {
                            doc.isActive = this.isDocumentActive(doc)
                            return doc
                        })

                    // make sure the first item in the list is active
                    if (output.length > 1 && !output[0].isActive) {

                        // get the first active doc
                        const firstActiveIndex: number = output.findIndex(doc => doc.isActive)

                        if (firstActiveIndex > -1) {
                            const firstActive: LiquidDocument = { ...output[firstActiveIndex] }

                            // if we found an active doc, remove it from the list then add it to the beginning
                            output.splice(firstActiveIndex, 1)
                            output.splice(0, 0, firstActive)
                        }
                    }
                    return output
                }),
            )
    }

    // invited and existing
    hasTeamMembers(organizationId: string): Observable<boolean> {
        return this.store.hasTeamMembers(organizationId)
    }

    isDocumentActive(doc: LiquidDocument): boolean {

        // if there's no end date, you have to assume it's active
        if (!doc.documentActiveToTimestamp) {
            return true
        }

        // set all the dates we're comparing to midnight
        const now: Date = new Date()
        now.setHours(0, 0, 0, 0)
        const endDate: Date = new Date(doc.documentActiveToTimestamp * 1000)
        endDate.setHours(0, 0, 0, 0)

        // if now is after end date, then the doc is active
        return endDate >= now
    }

    update(request: OrganizationTeamMemberUpdateRequest): Observable<{ organizationTeamMemberId: string, description: string }> {
        return this.store.updateClientVendorRelationship(request)
            .pipe(
                take(1),
            )
    }

    updateDocument(clientVendorRelationshipId: string, documentId: string, startDate: Date, endDate: Date): Observable<LiquidDocument> {
        const request: LiquidDocumentUpdateRequest = {
            documentActiveFrom: startDate?.toISOString(),
            documentActiveTo: endDate?.toISOString(),
            scope: LiquidDocumentUpdateRequestScope.DateUpdate,
        }
        return this.store.updateClientVendorRelationshipDocument(clientVendorRelationshipId, documentId, request)
            .pipe(
                take(1),
            )
    }

    updateRelationshipHiringManager(hiringManagerProfileId: string, relationshipId: string, scope: HiringManagerScopeRequest): Observable<ClientVendorRelationship> {

        if (!hiringManagerProfileId) {
            return throwError('A hiring manager profile ID is required.')
        }

        if (!relationshipId) {
            return throwError('A relationship ID is required.')
        }

        const request: ClientVendorRelationshipUpdatePropertiesRequest = {
            organizationId: this.users.businessSnapshot.id,
            organizationTeamMemberId: relationshipId,
            properties: {
                HiringManagerLiquidProfileId: hiringManagerProfileId,
            },
            updateScope: scope.updatePropertiesRequestScope,
        }
        return this.store.updateClientVendorRelationshipProperties(request)
            .pipe(
                take(1),
            )
    }

    updateInvitationHiringManager(hiringManagerProfileId: string, invitationId: string): Observable<OrganizationTeamInvitation> {

        if (!hiringManagerProfileId) {
            return throwError('A hiring manager profile ID is required.')
        }

        if (!invitationId) {
            return throwError('An invitation ID is required.')
        }

        const request: VendorInvitationUpdatePropertiesRequest = {
            invitationId,
            organizationId: this.users.businessSnapshot.id,
            properties: {
                HiringManagerLiquidProfileId: hiringManagerProfileId,
            },
            updateScope: UpdatePropertiesRequestScope.This,
        }

        return this.store.updateVendorInvitationProperties(request)
            .pipe(
                take(1),
            )
    }

    updateMasterContractAssociatedWorkOrders(clientVendorRelationshipId: string, masterContractId: string): Observable<LiquidDocument[]> {
        return this.store.updateMasterContractAssociatedWorkOrders(clientVendorRelationshipId, masterContractId)
            .pipe(
                take(1),
            )
    }

    uploadMasterContract(clientVendorRelationshipId: string, filename: string, data: FormData): Observable<LiquidDocument> {

        if (!clientVendorRelationshipId) {
            return throwError('A client-vendor relationship ID is required.')
        }

        if (!data) {
            return throwError('Data is required.')
        }

        return this.store.uploadClientVendorRelationshipMasterContract(clientVendorRelationshipId, filename, data)
            .pipe(
                take(1),
            )
    }
}
