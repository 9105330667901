import { Component, HostBinding, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { UserService } from '../../core/services'

@Component({
    selector: 'app-site-component',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss'],
})

export class SiteComponent implements OnDestroy {

    private ngUnsubscribe: Subject<void> = new Subject<void>()
    private readonly publicPaths: Array<string> = ['terms', 'privacy', 'generic-contract', 'plans', 'create-account', 'express-landing']

    showRouterOutlet: boolean

    // When window.self and window.top are different it means the component is in an iframe
    // The only component on an iframe on the site is plans.component
    @HostBinding('class.no-background') get onPricing(): boolean { return window.self !== window.top }

    constructor(
        router: Router,
        private users: UserService,
    ) {

        // initialize the user before doing anything else
        this.users.initializeUser()
            .pipe(
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe()

        const href: string = window.location.href
        if (this.publicPaths.some(path => href.includes(path))) {
            this.showRouterOutlet = true
        } else {
            router.navigateByUrl('/liquid/home')
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.unsubscribe()
    }
}
