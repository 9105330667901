import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Sort } from '@angular/material/sort'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { OrganizationMembershipAbstractMember } from '../../auth/models'
import { QuickBooksVendorFactory } from '../../modules/liquid/modules/vendor-associations/factories'
import {
    EmailAddress,
    ExpressOrganizationTeamInvitation,
    IntegrationEmailConfiguration,
    LiquidDocument,
    LiquidFinancialAccount,
    LiquidFinancialAccountType,
    OnboardingProcess,
    Organization,
    OrganizationAbstract,
    OrganizationMembershipAbstract,
    OrganizationMembershipInvitation,
    OrganizationMembershipInvitationResponse,
    OrganizationMembershipSignerFormDefaults,
    OrganizationMemberSignatureInformation,
    OrganizationQuestion,
    OrganizationQuestionAnswer,
    OrganizationSettings,
    OrganizationTeamConfiguration,
    OrganizationTeamInvitation,
    OrganizationTeamInvitationResponse,
    OrganizationTeamMembershipAbstract,
    OrganizationTeamMembershipVendorType,
    PublicOrganization,
    QuickBooksVendor,
    QuickBooksVendorRequest,
    TeamMemberSignerFormDefaults,
    VendorInfo,
    VendorListCounts,
    VendorListPageInfo,
    WorkOrderAbstract,
} from '../../modules/models'
import { BusinessFactory, ClaimsFactory } from '../factories'
import { CreateBusinessRequest, DeleteUserRequest, DeleteUserResponse, OrganizationRole } from '../models'

import { BusinessStore } from './business.store'
import { UrlService } from './url.service'

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {

    constructor(
        private readonly businessFactory: BusinessFactory,
        private readonly claims: ClaimsFactory,
        private readonly http: HttpClient,
        private readonly qbVendorFactory: QuickBooksVendorFactory,
        private readonly store: BusinessStore,
        private readonly urls: UrlService,
    ) { }

    getOrganizationById(orgId: string): Observable<Organization> {
        return this.http.get<Organization>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${orgId}`)
            .pipe(
                map(biz => this.businessFactory.create(biz)),
            )
    }

    getOrganizationAbstractByIds(organizationIds: string[]): Observable<OrganizationAbstract[]> {
        return this.http.post<OrganizationAbstract[]>(
            this.urls.api.organizationAbstract(), { ids: organizationIds },
        )
    }

    getOrganizationForClientInvoiceId(clientInvoiceId: string): Observable<Organization> {
        return this.http.get<Organization>(`${environment.liquidApiSettings.apiServicePrefix}/invoices/${clientInvoiceId}/clientOrganizations`)
    }

    updateOrganization(business: Organization, notifyOnSuccess?: boolean): Observable<Organization> {
        return this.store.updateBusiness(business, notifyOnSuccess)
            .pipe(
                take(1),
                map(biz => this.businessFactory.create(biz)),
            )
    }

    createOrganization(
        organization: Organization,
        ein: string,
        questionAnswers: OrganizationQuestionAnswer[],
        invitations: OrganizationMembershipInvitation[],
        createdByEmailAddress: string,
        isInternational: boolean,
        notifyOnSuccess?: boolean,
    ): Observable<Organization> {

        const request: CreateBusinessRequest = {
            createdByEmailAddress,
            ein,
            invitations,
            isInternational,
            notifyOnSuccess,
            questionAnswers,
            organization,
        }
        return this.store.createBusiness(request)
            .pipe(
                map(biz => this.businessFactory.create(biz)),
                take(1),
            )
    }

    getOrganizationExists(shortName: string): Observable<boolean> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organization-exists/' + shortName
        return this.http.get<boolean>(url)
    }

    getOrganizationByShortName(shortName: string, businessId: string): Observable<PublicOrganization> {
        let params: HttpParams = new HttpParams()
        params = params.append('orgId', businessId)
        return this.http.get<PublicOrganization>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/profiles/${shortName}`, { params })
    }

    getOrganizationSettings(organizationId: string): Observable<OrganizationSettings> {
        return this.http.get<OrganizationSettings>(this.urls.api.organizationSettings(organizationId))
    }

    organizationInviteOrganization(invitation: OrganizationMembershipInvitation): Observable<OrganizationMembershipAbstract> {
        return this.http.post<OrganizationMembershipAbstract>(environment.liquidApiSettings.apiServicePrefix + '/organizations/memberships/invitations', invitation)
    }

    removeOrganizationMembershipInvitation(organizationMembershipInvitationId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix + `/organizations/memberships/invitations/${organizationMembershipInvitationId}`)
    }

    organizationInviteSetupBot(organizationId: string): Observable<OrganizationMembershipAbstract> {
        return this.http.post<OrganizationMembershipAbstract>(
            environment.liquidApiSettings.apiServicePrefix + '/organizations/memberships/setupbot',
            { organizationId },
        )
    }

    getExpressOrganizationTeamInvitation(invitationId: string): Observable<ExpressOrganizationTeamInvitation> {
        return this.http.get<ExpressOrganizationTeamInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/public/organizations/teams/invitations/${invitationId}/express`)
    }

    getOrganizationMembershipAbstract(organizationId: string): Observable<OrganizationMembershipAbstract> {
        return this.http.get<OrganizationMembershipAbstract>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/members`)
    }

    getOrganizationMembershipInvitation(invitationId: string): Observable<OrganizationMembershipInvitation> {
        return this.http.get<OrganizationMembershipInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/public/organizations/memberships/invitations/${invitationId}`)
    }

    getOrganizationTeamInvitation(invitationId: string): Observable<OrganizationTeamInvitation> {
        return this.http.get<OrganizationTeamInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/public/organizations/teams/invitations/${invitationId}`)
    }

    getOrganizationFlatUnits(organizationId: string): Observable<string[]> {
        return this.http.get<string[]>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/flat-units`)
    }

    respondToMembershipInvitation(invitationResponse: OrganizationMembershipInvitationResponse): Observable<OrganizationMembershipInvitation> {
        return this.http.post<OrganizationMembershipInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/memberships/invitations/responses`,
            invitationResponse)
    }

    respondToTeamInvitation(invitationResponse: OrganizationTeamInvitationResponse): Observable<OrganizationTeamInvitation> {
        return this.http.post<OrganizationTeamInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/invitations/responses`,
            invitationResponse)
    }

    resendMembershipInvitation(invitationId: string): Observable<boolean> {
        return this.http.get<boolean>(environment.liquidApiSettings.apiServicePrefix + `/organizations/memberships/invitations/${invitationId}/resends`)
    }

    resendTeamInvitation(invitationId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + `/organizations/teams/invitations/${invitationId}/resends`, {})
    }

    updateOrganizationMembership(memId: string, desc: string, permissions: string[], reassignItems: boolean): Observable<OrganizationMembershipAbstract> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organizations/memberships'
        const body: any = {
            membershipId: memId,
            description: desc,
            permissions,
            reassignItems,
        }

        return this.http.put<OrganizationMembershipAbstract>(url, body)
    }

    updateOrganizationMembershipInvitation(invitationId: string, desc: string, permissions: string[]): Observable<OrganizationMembershipAbstract> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organizations/memberships/invitations'
        const body: any = {
            id: invitationId,
            description: desc,
            permissions,
        }

        return this.http.put<OrganizationMembershipAbstract>(url, body)
    }

    updateOrganizationSettings(organizationId: string, organizationSettings: OrganizationSettings): Observable<OrganizationSettings> {
        return this.http.put<OrganizationSettings>(this.urls.api.organizationSettings(organizationId), organizationSettings)
    }

    batchUpdateOrganizationMemberships(requests: Array<OrganizationMembershipAbstractMember>, reassignItems: boolean = false): Observable<OrganizationMembershipAbstract> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organizations/memberships/batches'
        const requestsMapped: Array<any> = requests.map(r => {
            return { ...r, reassignItems }
        })

        return this.http.put<OrganizationMembershipAbstract>(url, requestsMapped)
    }

    removeOrganizationMembership(membershipId: string, reassignItems: boolean = false): Observable<DeleteUserResponse> {
        const url: string = this.urls.api.userDelete(membershipId)
        const body: DeleteUserRequest = {
            reassignItems: !!reassignItems,
        }
        // need to use generic request function here in order to pass a body to a delete method
        return this.http.request<DeleteUserResponse>('delete', url, { body })
    }

    createOrganizationTeamInvitation(
        clientInvoiceId: string,
        contactId: string,
        countersigningEnabled: boolean,
        contractSignerProfileId: string,
        organizationId: string,
        inviteeDescription: string,
        inviteeOrganizationId: string,
        inviteeEmailAddress: string,
        inviteeName: string,
        inviteeType: OrganizationTeamMembershipVendorType,
        message: string,
        onboardingProcess: OnboardingProcess,
        inviteeQuickBooksVendorId: string,
        hiringManagerProfileId: string,
        masterContractActiveFrom: Date,
        masterContractActiveTo: Date,
        vendorBankAccountNumber?: string,
        vendorRoutingNumber?: string,
    ): Observable<OrganizationTeamInvitation> {

        const request: any = {
            vendorBankAccountNumber,
            clientInvoiceId,
            contactId,
            countersigningEnabled,
            contractSignerProfileId,
            hiringManagerProfileId,
            inviteeDescription,
            inviteeEmailAddress,
            inviteeName,
            inviteeType,
            inviteeOrganizationId,
            inviteeQuickBooksVendorId,
            masterContractActiveFrom,
            masterContractActiveTo,
            message,
            onboardingProcess,
            organizationId,
            vendorRoutingNumber,
        }

        return this.http.post<OrganizationTeamInvitation>(`${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/invitations`,
            request)
    }

    uploadAvatar(organizationId: string, data: FormData): Observable<Organization> {
        return this.http.post<Organization>(environment.liquidApiSettings.apiServicePrefix + '/organizations/' + organizationId + '/avatars',
            data)
    }

    uploadLogo(organizationId: string, data: FormData): Observable<Organization> {
        return this.http.post<Organization>(environment.liquidApiSettings.apiServicePrefix + '/organizations/' + organizationId + '/logos',
            data)
    }

    removeOrganizationLogo(organizationId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix + '/organizations/' + organizationId + '/logos')
    }

    removeOrganizationAvatar(organizationId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix + '/organizations/' + organizationId + '/avatars')
    }

    getImageFromUrl(imageUrl: string): Observable<HttpResponse<Blob>> {
        return this.http.get(imageUrl, { observe: 'response', responseType: 'blob' })
    }

    getWebUrl(webUrl: string): string | undefined {
        const safeUrl: string = !!webUrl ? webUrl.trim() : undefined
        return !!safeUrl ? `https://${safeUrl.replace(/https?:\/\//, '')}` : undefined
    }

    getOrganizationProfileQuestions(): Observable<OrganizationQuestion[]> {
        return this.http.get<OrganizationQuestion[]>(environment.liquidApiSettings.apiServicePrefix + '/public/organizations/profilequestions')
    }

    addOrganizationTeamMemberToTeamMemberGroup(orgTeamMemberId: string, orgTeamMemberGroupId: string): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/organizations/teams/groups/members',
            { organizationTeamMemberId: orgTeamMemberId, organizationTeamMemberGroupId: orgTeamMemberGroupId })
    }

    dropOrganizationTeamMemberFromGroup(orgTeamMemberId: string, orgTeamMemberGroupId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/organizations/teams/groups/${orgTeamMemberGroupId}/members/${orgTeamMemberId}`)
    }

    toggleHideTeamMemberFromTeam(calledByOrganizationId: string, organizationTeamMemberId: string): Observable<OrganizationTeamMembershipAbstract> {
        return this.http.put<OrganizationTeamMembershipAbstract>(environment.liquidApiSettings.apiServicePrefix +
            `/organizations/${calledByOrganizationId}/teams/members/${organizationTeamMemberId}`, {})
    }

    sendOrganizationTeamMemberMessage(organizationTeamMemberId: string, message: string, messageIsForClientOrganization: boolean = false): Observable<boolean> {
        return this.http.post<boolean>(environment.liquidApiSettings.apiServicePrefix + '/organizations/teams/members/messages',
            { organizationTeamMemberId, message, messageIsForClientOrganization })
    }

    cancelInvitationToOrganization(organizationTeamMemberInvitationId: string, reason?: string): Observable<boolean> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('reason', reason)
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/organizations/teams/invitations/${organizationTeamMemberInvitationId}`, { headers })
    }

    getTeamConfigConfiguration(organizationId: string): Observable<OrganizationTeamConfiguration> {
        const url: string = `${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/teamConfigurations`
        return this.http.get<OrganizationTeamConfiguration>(url)
            .pipe(
                tap(config => config.myClaims = this.claims.create(config)),
            )
    }

    downloadTeamMemberDocument(document: LiquidDocument, organizationTeamMemberId: string): Observable<boolean> {
        return this.http.get(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/members/${organizationTeamMemberId}/files/${document.id}/${document.originalFilename}`,
            { observe: 'response', responseType: 'blob' },
        )
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    FileSaver.saveAs(response.body, document.originalFilename)
                    return true
                }),
            )
    }

    downloadInvitationDocument(document: LiquidDocument, invitationId: string): Observable<boolean> {
        return this.http.get(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/invitations/${invitationId}/files/${document.id}/${document.originalFilename}`,
            { observe: 'response', responseType: 'blob' },
        )
            .pipe(
                map(response => {
                    FileSaver.saveAs(response.body, document.originalFilename)
                    return true
                }),
            )
    }

    getAssociatedWorkOrdersForContract(organizationTeamMemberId: string, documentId: string): Observable<WorkOrderAbstract[]> {
        return this.http.get<WorkOrderAbstract[]>(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/members/${organizationTeamMemberId}/files/${documentId}/associatedWorkOrders`)
    }

    getDocumentsForOrganizationInvitee(organizationTeamInvitationId: string): Observable<LiquidDocument[]> {
        return this.http.get<LiquidDocument[]>(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/invitations/${organizationTeamInvitationId}/documents`)
    }

    removeOrganizationTeamMemberDocument(calledByOrganizationId: string, organizationTeamMemberId: string, documentId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/organizations/${calledByOrganizationId}/teams/members/${organizationTeamMemberId}/documents/${documentId}`)
    }

    getTeamMemberSignerFormDefaults(organizationTeamMemberId: string): Observable<TeamMemberSignerFormDefaults> {
        return this.http.get<TeamMemberSignerFormDefaults>(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/teams/members/${organizationTeamMemberId}/formDefaults`)
    }

    createOrganizationMemberFormDefaults(organizationId: string, signatureInformation: OrganizationMemberSignatureInformation): Observable<OrganizationMembershipSignerFormDefaults> {
        return this.http.post<OrganizationMembershipSignerFormDefaults>(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/members/formDefaults`,
            signatureInformation,
        )
    }

    getOrganizationMembershipSignerFormDefaults(organizationId: string): Observable<OrganizationMembershipSignerFormDefaults> {
        return this.http.get<OrganizationMembershipSignerFormDefaults>(
            `${environment.liquidApiSettings.apiServicePrefix}/organizations/${organizationId}/members/formDefaults`)
    }

    getW8sForTeam(organizationId: string, filePassword: string, filename: string): Observable<boolean> {
        return this.http.post(
            this.urls.api.w8ReportFile(),
            { organizationId, filePassword, format: 'xlsx' },
            { observe: 'response', responseType: 'blob' },
        )
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    FileSaver.saveAs(response.body, filename)
                    return true
                }),
            )
    }

    getW9sForTeam(organizationId: string, filePassword: string, filename: string): Observable<boolean> {
        return this.http.post(
            `${environment.liquidApiSettings.apiServicePrefix}/reports/organizations/w9s`,
            { organizationId, filePassword, format: 'xlsx' },
            { observe: 'response', responseType: 'blob' },
        )
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    FileSaver.saveAs(response.body, filename)
                    return true
                }),
            )
    }

    getAllOrganizationNotificationEmailAddresses(): Observable<EmailAddress[]> {
        return this.http.get<EmailAddress[]>(environment.liquidApiSettings.apiServicePrefix + '/organizations/teams/members/emailaddresses')
    }

    updateOrganizationNotifcationEmailAddress(address: EmailAddress): Observable<EmailAddress> {
        return this.http.post<EmailAddress>(
            environment.liquidApiSettings.apiServicePrefix + `/organizations/teams/members/emailAddresses`,
            address)
    }

    uploadOrganizationTeamMemberDocument(organizationTeamMemberId: string, filename: string, data: FormData): Observable<boolean> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organizations/teams/members/'
            + organizationTeamMemberId + '/documents/' + filename
        return this.http.post<boolean>(url, data)
    }

    uploadOrganizationTeamMemberMasterContract(organizationTeamMemberId: string, filename: string, data: FormData): Observable<boolean> {
        const url: string = environment.liquidApiSettings.apiServicePrefix + '/organizations/teams/members/'
            + organizationTeamMemberId + '/masterContracts/' + filename
        return this.http.post<boolean>(url, data)
    }

    getLiquidCOA(organizationId: string): Observable<LiquidFinancialAccount[]> {
        return this.http.get<LiquidFinancialAccount[]>(`${environment.liquidApiSettings.apiServicePrefix}/accounting/organizations/${organizationId}/chartOfAccounts`)
    }

    getLiquidFinancialAccountTypes(): Observable<LiquidFinancialAccountType[]> {
        return this.http.get<LiquidFinancialAccountType[]>(`${environment.liquidApiSettings.apiServicePrefix}/accounting/accountTypes`)
    }

    createLiquidFinancialAccount(account: LiquidFinancialAccount): Observable<LiquidFinancialAccount> {
        return this.http.post<LiquidFinancialAccount>(
            `${environment.liquidApiSettings.apiServicePrefix}/accounting/organizations/chartOfAccounts/accounts`,
            account,
        )
    }

    editLiquidFinancialAccount(account: LiquidFinancialAccount): Observable<LiquidFinancialAccount> {
        return this.http.patch<LiquidFinancialAccount>(
            `${environment.liquidApiSettings.apiServicePrefix}/accounting/organizations/chartOfAccounts/accounts`,
            account,
        )
    }

    deleteLiquidFinancialAccount(accountId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/accounting/organizations/chartOfAccounts/accounts/${accountId}`)
    }

    addEin(einObj: { ein: string, organizationId: string, einIsSsn: boolean }): Observable<Organization> {
        return this.http.post<Organization>(environment.liquidApiSettings.apiServicePrefix +
            '/organizations/eins', einObj)
    }

    getIntegrationEmailConfigurationsForOrganization(organizationId: string): Observable<IntegrationEmailConfiguration[]> {
        return this.http.get<IntegrationEmailConfiguration[]>(environment.liquidApiSettings.apiServicePrefix +
            `/accounting/organizations/${organizationId}/emailIntegrations/configurations`)
    }

    createIntegrationEmailConfiguration(config: IntegrationEmailConfiguration): Observable<IntegrationEmailConfiguration> {
        return this.http.post<IntegrationEmailConfiguration>(environment.liquidApiSettings.apiServicePrefix +
            `/accounting/organizations/emailIntegrations/configurations`, config)
    }

    updateIntegrationEmailConfiguration(config: IntegrationEmailConfiguration): Observable<IntegrationEmailConfiguration> {
        return this.http.patch<IntegrationEmailConfiguration>(environment.liquidApiSettings.apiServicePrefix +
            `/accounting/organizations/emailIntegrations/configurations`, config)
    }

    deleteIntegrationEmailConfiguration(configId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.liquidApiSettings.apiServicePrefix +
            `/accounting/organizations/emailIntegrations/configurations/${configId}`)
    }

    createNewQuickBooksVendor(organizationId: string, vendorOrganizationId: string, qbVendorName: string): Observable<QuickBooksVendor> {
        return this.http.post<QuickBooksVendorRequest>(
            `${environment.liquidApiSettings.apiServicePrefix}/accounting/organizations/quickBooks/vendors`,
            { organizationId, vendorOrganizationId, qbVendorName },
        )
            .pipe(
                map(qbVendorsRequest => this.qbVendorFactory.create(qbVendorsRequest)),
            )
    }

    getRoles(forWorkers: boolean, forHirers: boolean): Observable<Array<OrganizationRole>> {

        let url: string = `${environment.liquidApiSettings.apiServicePrefix}/organizations/roles/unknownType`

        if (forWorkers && !forHirers) {
            url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/roles/workers`
        } else if (forHirers && !forWorkers) {
            url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/roles/hirers`
        } else if (forHirers && forWorkers) {
            url = `${environment.liquidApiSettings.apiServicePrefix}/organizations/roles/serviceOrganizations`
        }

        return this.http.get<Array<OrganizationRole>>(url)
    }

    updateOrganizationCurrencyPreference(businessId: string, prefersLocal: boolean): Observable<Organization> {
        return this.http.put<Organization>(
            this.urls.api.businessCurrencyPreference(businessId),
            { preferVendorLocalCurrency: prefersLocal },
        )
    }

    getVendorInfos(bizId: string): Observable<Array<VendorInfo>> {
        return this.http.get<Array<VendorInfo>>(this.urls.api.vendorInfos(bizId))
    }

    getVendorList(bizId: string, page: Partial<PageEvent>, sort: Sort, filters?: { [key: string]: string }): Observable<VendorListPageInfo> {
        let params: HttpParams = new HttpParams()
        params = params.appendAll({
            page: String(page.pageIndex + 1),
            pageSize: String(page.pageSize),
            sortString: `${sort.active} ${sort.direction}`,
        })

        return this.http.post<VendorListPageInfo>(this.urls.api.vendorList(bizId), filters, { params })
    }

    getVendorListCounts(bizId: string): Observable<VendorListCounts> {
        return this.http.get<VendorListCounts>(this.urls.api.vendorListCounts(bizId))
    }

    getVendorsCanBeInvoiced(clientOrganizationId: string): Observable<OrganizationAbstract[]> {
        return this.http.get<OrganizationAbstract[]>(
            this.urls.api.vendorsCanbeInvoiced(clientOrganizationId),
        )
    }

    completeClientSetup(bizId: string): Observable<void> {
        return this.http.post<void>(this.urls.api.setupComplete(bizId), {})
            .pipe(
                take(1),
            )
    }
}
