import { CommonModule, CurrencyPipe } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TextMaskModule } from 'angular2-text-mask'
import { DragulaModule } from 'ng2-dragula'

import { ChipListModule } from '../../chip-list/chip-list.module'
import { LiquidCommonModule } from '../../common/liquid-common.module'
import { InSituEditTextFieldModule } from '../../in-situ-edit-text-field/in-situ-edit-text-field.module'
import { PipesModule } from '../../pipes'
import { AppCommonModule } from '../liquid/modules/app-common/app-common.module'
import { MadLibsModule } from '../liquid/modules/mad-libs/mad-libs.module'

import { DeliverableEditComponent } from './deliverable-edit.component'
import { DeliverableFormComponent } from './deliverable-form/deliverable-form.component'
import { DeliverableListComponent } from './deliverable-list/deliverable-list.component'
import { UnsavedChangesDialogComponent } from './dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component'
import { OrderItemComponent } from './order-item/order-item.component'
import { RecurringPickerComponent } from './recurring-picker.component'
import { TimeTrackingEntryComponent } from './time-tracking-entry.component'
import { TimeTrackingComponent } from './time-tracking.component'
import { TimeTrackingService } from './time-tracking.service'
import { WorkOrderDeliverableListComponent } from './work-order-deliverable-list.component'
import { WorkOrderSummaryComponent } from './work-order-summary/work-order-summary.component';
import { WorkOrderVerbiageComponent } from './work-order-verbiage/work-order-verbiage.component';

@NgModule({
    imports: [
        AppCommonModule,
        ChipListModule,
        CommonModule,
        DragulaModule,
        FormsModule,
        InSituEditTextFieldModule,
        LiquidCommonModule,
        MadLibsModule,
        MadLibsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatTooltipModule,
        PipesModule,
        ReactiveFormsModule,
        TextMaskModule,
    ],
    providers: [
        CurrencyPipe,
    ],
    declarations: [
        DeliverableEditComponent,
        WorkOrderDeliverableListComponent,
        RecurringPickerComponent,
        DeliverableFormComponent,
        DeliverableListComponent,
        TimeTrackingComponent,
        TimeTrackingEntryComponent,
        UnsavedChangesDialogComponent,
        WorkOrderSummaryComponent,
        OrderItemComponent,
        WorkOrderVerbiageComponent,
    ],
    exports: [
        DeliverableEditComponent,
        DeliverableListComponent,
        OrderItemComponent,
        ReactiveFormsModule,
        RecurringPickerComponent,
        TimeTrackingComponent,
        TimeTrackingEntryComponent,
        WorkOrderDeliverableListComponent,
        WorkOrderSummaryComponent,
        WorkOrderVerbiageComponent,
    ],
})
export class WorkOrdersCommonModule {

    static forRoot(): ModuleWithProviders<WorkOrdersCommonModule> {
        return {
            ngModule: WorkOrdersCommonModule,
            providers: [
                TimeTrackingService,
            ],
        }
    }
}
