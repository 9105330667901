import { EventEmitter, Injectable, Output, TemplateRef } from '@angular/core'
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import moment from 'moment'
import { tz } from 'moment-timezone'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { environment } from '../../../../../../environments/environment'
import { OrganizationMembershipAbstractMember } from '../../../../../auth/models'
import { GAAction, GACategory, GATracking } from '../../../../../common/directives/ga-tracker/models'
import { GaTrackingService } from '../../../../../common/directives/ga-tracker/services'
import { StorageKey } from '../../../../../common/global'
import {
    BusinessUsersService,
    ClientVendorRelationshipService,
    OnboardingService,
    OrganizationService,
    UserService
} from '../../../../../core/services'
import { DialogService, DialogTemplateType } from '../../../../../dialogs'
import { HandledError } from '../../../../../error-handling/handled-error.interface'
import {
    BusinessTeamMemberStates,
    Deliverable,
    LiquidDocumentUpdateRequestScope,
    OnboardingProcess,
    OnboardingStepType,
    OnboardingTemplateType,
    OrderType,
    Organization,
    OrganizationSettings,
    OrganizationTeamConfiguration,
    OrganizationTeamInvitation,
    OrganizationTeamMembershipVendorType,
    RateType,
    RateTypeSelectOption,
    ShadowRateType,
    TeamMemberAbstract,
    TeamMembership,
    WorkOrder
} from '../../../../models'
import { WorkOrderService } from '../../../../services'
import { DeliverableListComponent } from '../../../../work-orders-common/deliverable-list/deliverable-list.component'
import { getOrderTypeConfig, OrderTypeConfig } from '../../../../work-orders-common/order-type.utils'
import { SendMessageComponent } from '../../messaging/send-message.component'
import { InviteVendorStepId, VendorInvite } from '../../vendor-invite-common/models'
import { InviteService } from '../../vendor-invite-common/services/invite-service.interface'
import { VendorInviteCommonService } from '../../vendor-invite-common/services/vendor-invite-common.service'
import { WorkflowComponent, WorkflowModule, WorkflowService, WorkflowStep, WorkflowStepGroup, WorkflowStepIndices } from '../../workflow'
import { FormStepDataInput } from '../../workflow/form-step/form-step-data-input.interface'
import { WorkflowStepMap } from '../../workflow/models/step-map.interface'
import { CreateOrderDecision } from '../config/create-order-decision.enum'
import { CreateOrderSteps } from '../config/create-order-stepIds.enum'
import { DeliverableValidator } from '../deliverable.validator'

import { FormState } from './form-state.interface'
import { CreateWorkOrderConfig } from './work-order-helper-config.interface'

interface InvitedMember {
    inviteId: string,
    name?: string
}

@Injectable({
    providedIn: 'root',
})
export class CreateWorkOrderService implements InviteService {

    private constantRateTypeOptions: Array<RateTypeSelectOption> = [
        { value: { type: RateType.Flat }, label: 'as a flat fee of' },
        { value: { type: RateType.Hourly }, label: 'at an hourly rate of' },
        { value: { type: RateType.Daily }, label: 'at a daily rate of' },
        { value: { type: RateType.Weekly }, label: 'at a weekly rate of' },
        { value: { type: RateType.SemiMonthly }, label: '1st & 15th' },
        { value: { type: RateType.Monthly }, label: 'at a monthly rate of' },
        { value: { type: RateType.Quarterly }, label: 'at a quarterly rate of' },
        { value: { type: RateType.Custom }, label: 'as follows' },
    ]
    private clientValidationMessage: TemplateRef<any>
    private newFlatUnitTypeOption: RateTypeSelectOption = { value: { type: ShadowRateType.NewFlatUnit }, label: 'Create unit type' }
    private modules: Array<WorkflowModule>
    private unsubscribe$: Observable<void>
    private vendorValidationMessage: TemplateRef<any>
    private workflowComponent: WorkflowComponent

    @Output() detectChanges: EventEmitter<void> = new EventEmitter()

    afterClosed: Observable<any>
    attachedInvite?: OrganizationTeamInvitation
    autoCreateDrafts: FormControl = new FormControl(true)
    availableRateTypeOptions: Array<RateTypeSelectOption> = [...this.constantRateTypeOptions, ...[this.newFlatUnitTypeOption]]
    business: Organization
    readonly clientControl: FormControl = new FormControl(undefined, Validators.required)
    clients: Array<TeamMembership> = []
    cloned: boolean
    confirmedDismissChanges: boolean = false
    confirmDatesInPast: FormControl = new FormControl(false, Validators.requiredTrue)
    currentTimezone: string
    deliverableListRef: DeliverableListComponent
    readonly deliverableValidationControl: FormControl = new FormControl([])
    deliverables: FormControl = new FormControl([], [Validators.required, DeliverableValidator])
    draftSaved: boolean = false
    editingDeliverable: boolean = true
    existingVendors: Array<TeamMemberAbstract> = []
    invite: VendorInvite
    invitedVendors: Array<InvitedMember> = []
    isFetchingMasterContract: boolean = false
    isLoading: boolean = false
    isWorker: boolean = false
    hiringManager: OrganizationMembershipAbstractMember
    masterContractControl: FormControl = new FormControl(undefined)
    masterContractData: FormData
    orderTypeConfig: OrderTypeConfig
    ownVendorTracking: boolean
    readonly rateTypesEnum: typeof RateType & typeof ShadowRateType = { ...RateType, ...ShadowRateType }
    selectedClient: TeamMembership
    selectedVendor: TeamMemberAbstract
    settings: OrganizationSettings
    stepMap: WorkflowStepMap
    teamConfig: OrganizationTeamConfiguration
    uploadedFile: File
    useDialogs: boolean = false
    readonly vendorControl: FormControl = new FormControl()
    vendorNameOverride: string
    readonly vendorOverrideControl: FormControl = new FormControl(undefined)
    workOrder: WorkOrder

    set clientId(id: string) { this.workOrder.clientOrganizationId = id }

    get clientVendorRelationshipId(): string { return this.isWorker ? this.selectedClient?.id : this.selectedVendor?.organizationTeamMemberId }

    get datesInPast(): Array<{ deliverable: Deliverable, fields: Array<string> }> { return this.getDatesInPast() }

    get deliverableForms(): Array<FormState> { return this.deliverableValidationControl?.value }
    set deliverableForms(value: Array<FormState>) { this.deliverableValidationControl?.setValue(value) }

    get fullWorkOrder(): WorkOrder { return this.getFullWorkOrder() }

    get masterContractId(): string { return this.masterContractControl.value }

    get orderType(): OrderType { return this.workOrder?.type || this.orderTypeConfig.type }

    set showDismissConfirmation(value: boolean) { this.workflow.showDismissMessage = value }

    set vendorId(id: string) { this.workOrder.workerOrganizationId = id }

    constructor(
        private readonly businessUsers: BusinessUsersService,
        private readonly clientVendorRelationships: ClientVendorRelationshipService,
        private readonly dialogs: DialogService,
        private readonly ga: GaTrackingService,
        private readonly organizations: OrganizationService,
        private readonly onboardings: OnboardingService,
        private readonly users: UserService,
        private readonly vendorInviteCommonService: VendorInviteCommonService,
        private readonly workflow: WorkflowService,
        private readonly workOrdersService: WorkOrderService,
    ) { }

    changeCountersigningEnabled(value: boolean): void {
        this.workOrder.vendorInvitationInformation.countersigningEnabled = value
    }

    changeOnboardingProcess(process: OnboardingProcess): void {
        const onboardingGroupIndexes: WorkflowStepIndices = this.workflow.stepMap[InviteVendorStepId.OnboardingProcessUploadMasterContract]
        const group: WorkflowStepGroup = this.modules[onboardingGroupIndexes.moduleIndex].groups[onboardingGroupIndexes.groupIndex]

        const steps: WorkflowStep[] = [...group.steps]
        this.vendorInviteCommonService.removePreInviteSteps(steps)

        const preInviteSteps: WorkflowStep[] = this.vendorInviteCommonService.getPreInviteSteps(
            process,
            {
                showOn: {
                    [CreateOrderSteps.vendorUsage]: CreateOrderDecision.StandardFlow,
                },
            },
        )

        let indexToInsert: number = onboardingGroupIndexes.stepIndex + 1
        for (const preInviteStep of preInviteSteps) {
            steps.splice(indexToInsert++, 0, preInviteStep)
        }

        group.steps = steps

        this.invite.onboardingProcess = process

        const continueUpdating: boolean = this.vendorInviteCommonService
            .handleUploadMasterContractSteps(process, group, this.modules, this.workflowComponent)

        if (!continueUpdating) {
            return
        }

        this.invite.masterContract = undefined
        this.masterContractData = undefined
        this.uploadedFile = undefined
    }

    getCountersigningEnabled(): boolean {
        return this.workOrder.vendorInvitationInformation?.countersigningEnabled
    }

    getSettings(): OrganizationSettings {
        return this.settings
    }

    getOnboardingProcess(): OnboardingProcess {
        return this.invite?.onboardingProcess
    }

    setFormGroup(inputs: FormStepDataInput[], validators?: ValidatorFn[]): void {
        const form: FormGroup = this.workflow.form
        Object.keys(form.controls).forEach(key => form.removeControl(key))
        form.setValidators([])
        inputs.forEach(input => {
            let value: any = this.workOrder?.vendorInvitationInformation?.[input.name] || this.invite?.[input.name] || input.value
            // file inputs should be set manually in AfterViewInit with emitModelToViewChange: false option (e.g. InviteStepUploadComponent)
            if (input.type === 'file') {
                value = undefined
            }
            const control: FormControl = new FormControl(value, input.validators)
            form.addControl(input.name, control)
        })
        if (!!validators) {
            form.setValidators(validators)
        }
        form.updateValueAndValidity()

    }

    discardDraft(workOrder: WorkOrder): Observable<WorkOrder> {
        this.workflow.isLoading = true
        return this.workOrdersService.discardDraft(workOrder)
            .pipe(
                tap(() => {
                    this.workflow.isLoading = false
                    window.history.pushState({}, '', this.isWorker ? this.orderTypeConfig.vendorDashboardUrl : this.orderTypeConfig.clientDashboardUrl)
                }),
            )
    }

    handleDialogClose(): void {
        if (this.hasDirtyForms()) {
            this.handleDirtyForms()
        } else {
            this.dialogs.close()
        }
    }

    handleDirtyFormsConfirmation(value: boolean): void {
        if (value) {
            this.dialogs.close()
        } else {
            this.confirmedDismissChanges = true
        }
        this.showDismissConfirmation = undefined
    }

    hasDirtyForms(): boolean {
        return this.deliverableForms.some(state => state.dirty || state.invalid)
            || this.clientControl.dirty
            || this.vendorControl.dirty
            || this.vendorOverrideControl.dirty
            || this.workflow.form.dirty
    }

    initialize(config: CreateWorkOrderConfig, stepMap: WorkflowStepMap): void {

        if (!!config?.workOrder) {
            this.workOrder = config.workOrder
        } else {
            const orderStorageKey: string = config?.orderType === OrderType.WorkOrder ? StorageKey.workOrder : StorageKey.purchaseOrder
            const defaultOrder: Partial<WorkOrder> = { name: '', initiatedByOrganizationId: '', initiatedInTimezone: '', deliverables: [], type: config.orderType }
            const initialOrder: WorkOrder =
                !!config?.skipDraft
                    ? defaultOrder
                    : JSON.parse(localStorage.getItem(orderStorageKey)) || defaultOrder

            this.workOrder = initialOrder
        }

        this.orderTypeConfig = getOrderTypeConfig(config.orderType || OrderType.WorkOrder)
        this.deliverables.setValue(this.workOrder.deliverables || [])
        this.masterContractControl.setValue(this.workOrder?.masterContractLiquidDocumentId)

        this.cloned = config.cloned
        this.isWorker = !!config?.isWorker
        this.useDialogs = !!config?.useDialogs
        this.currentTimezone = tz.guess()
        this.unsubscribe$ = config.unsubscribe$
        this.workflow.disableNext = false
        this.workflow.implementationService = this
        this.ownVendorTracking = undefined
        this.invite = undefined

        if (config?.business) {
            this.business = config.business
        }

        if (!!this.workOrder?.hiringManagerProfileId && (!this.hiringManager?.profileId || this.hiringManager?.profileId !== this.workOrder?.hiringManagerProfileId)) {
            const request: any = { hiringManagerProfileId: this.workOrder.hiringManagerProfileId }
            this.hiringManager = this.clientVendorRelationships.getHiringManager(request)
        }

        this.modules = config.modules
        this.stepMap = stepMap
        this.initializeWorkflow(config.stepId)

        this.vendorInviteCommonService.initialize(this)

        if (!!config.workOrder?.vendorInvitationInformation) {
            this.workflow.setDecisions({
                [CreateOrderDecision.AddNewVendor]: true,
            })
        }

        this.users.teamConfig$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(teamConfig => this.teamConfig = teamConfig),
                tap(() => this.setClientsAndVendors()),
                filter(() => !!config?.clientId || !!config?.vendorId),
                tap(() => {
                    if (!!config?.clientId) {
                        this.setClient(config?.clientId)
                    }
                    if (!!config?.vendorId) {
                        this.setVendor(config.vendorId)
                    }
                }),
            )
            .subscribe()
    }

    initializeComponent(workflowComponent: WorkflowComponent): void {
        this.workflow.genericStepFunction$ = undefined
        this.workflowComponent = workflowComponent
    }

    refreshModules(): void {
        this.workflowComponent.moduleComponents.forEach(module => module.groupComponent.refreshStep())
    }

    reset(initializedModules: Array<WorkflowModule>): void {

        // reset the modules from scratch
        this.modules = initializedModules
        this.detectChanges.emit()

        this.draftSaved = false
        this.workOrder = {
            autoCreateInvoiceDrafts: false,
            currency: undefined, // this is set in abstracts, so ignored otherwise?
            hiringManageAvatarUrl: undefined,
            hiringManagerProfileId: undefined,
            hiringManagerName: undefined,
            initiatedByOrganizationId: '',
            initiatedInTimezone: '',
            name: '',
            deliverables: [],
            vendorNameOverride: '',
            paymentTermsDays: undefined,
            type: undefined,
        }

        this.resetForms()

        this.confirmedDismissChanges = undefined
        this.showDismissConfirmation = undefined

        this.ownVendorTracking = undefined
        this.invite = undefined

        this.masterContractControl.setValue(undefined)
        this.deliverables.setValue([])
        this.deliverableValidationControl.setValue([])
        this.confirmDatesInPast.setValue(false)

        this.workflow.maxEnabledModuleIndex = 0
        this.workflow.maxEnabledGroupIndex = 0
        this.workflow.maxEnabledStepIndex = 0
        this.workflow.goToModule(0)
        this.workflow.initialize(this.modules, this.stepMap)
        this.refreshModules()
        this.resetStorage()
    }

    saveFormState(state: FormState): void {
        const forms: Array<FormState> = [...this.deliverableForms]
        if (!forms[state.index] || !this.deliverables.value[state.index]) {
            return
        }
        forms.splice(state.index, 1, state)
        this.deliverableValidationControl.setValue(forms, { emitEvent: true })
    }

    saveHiringManager(): void {
        if (!this.workOrder?.id) {
            return
        }
        this.workflow.isLoading = true
        this.workOrdersService.updateWorkOrderHiringManager(this.workOrder.hiringManagerProfileId, this.workOrder.id)
            .pipe(
                take(1),
                tap(() => this.workflow.isLoading = false),
                catchError(err => {
                    this.workflow.isLoading = false
                    this.workflow.error = err
                    return of(undefined)
                }),
            )
            .subscribe()
    }

    setAutoCreateInvoiceDrafts(val: boolean): void {
        if (!this.workOrder?.id) {
            return
        }
        this.workflow.isLoading = true
        this.workOrder.autoCreateInvoiceDrafts = val
        this.workOrdersService.setAutoCreateInvoiceDrafts(val, this.workOrder.id)
            .pipe(
                take(1),
                tap(() => this.workflow.isLoading = false),
                catchError(err => {
                    this.workflow.isLoading = false
                    this.workflow.error = err
                    return of(undefined)
                }),
            )
            .subscribe()
    }

    sendMessageToClient(): void {
        const sendMessageConfig: MatDialogConfig = new MatDialogConfig()
        sendMessageConfig.autoFocus = true

        const ref: MatDialogRef<SendMessageComponent> = this.dialogs.open(SendMessageComponent, sendMessageConfig, DialogTemplateType.medium)
        ref.componentInstance.membership = this.clientControl.value
        ref.componentInstance.isForClientOrganization = true

        this.afterClosed = ref.afterClosed()
    }

    setAvailableRateTypeFlatUnits(flatUnits: string[]): void {
        if (!flatUnits?.length) {
            return
        }
        const createNewUnitOption: RateTypeSelectOption = this.newFlatUnitTypeOption
        const customFlatUnitsOptions: RateTypeSelectOption[] =
            flatUnits.map(flatUnit => ({ value: { type: ShadowRateType.CustomFlatUnit, description: flatUnit }, label: flatUnit }))
        this.availableRateTypeOptions = [...this.constantRateTypeOptions, ...customFlatUnitsOptions, ...[createNewUnitOption]]
    }

    setBusiness(biz: Organization): void {
        this.business = biz
    }

    setClient(clientId: TeamMembership | string): void {
        const client: TeamMembership = typeof clientId === 'string' ? this.clients.find(c => c.membershipIsToOrganization?.id === clientId) : clientId
        if (!client) {
            return
        }
        const shouldCheckMasterContract: boolean = this.selectedClient?.id !== client?.id
        this.selectedClient = client
        this.refreshWorkOrderOrgs()
        if (!this.workOrder.hiringManagerProfileId) {
            this.setDefaultHiringManager(client.membershipIsToOrganization.id, this.users.businessSnapshot.id)
        }
        if (shouldCheckMasterContract) {
            this.saveRecipient()
                .subscribe()
        }
    }

    setClientValidationMessage(message: TemplateRef<any>): void {
        this.clientValidationMessage = message
    }

    setHiringManager(hiringManager: OrganizationMembershipAbstractMember): void {
        this.hiringManager = hiringManager
        this.workOrder.hiringManagerProfileId = hiringManager.profileId
        this.workOrder.hiringManagerName = this.getFullName(hiringManager)

        if (!!this.workOrder.vendorInvitationInformation) {
            this.workOrder.vendorInvitationInformation.hiringManagerProfileId = hiringManager.profileId
        }
    }

    setInvite(invite: OrganizationTeamInvitation): void {
        this.attachedInvite = invite
        this.vendorNameOverride = invite.inviteeName
    }

    setInvitedVendor(vendor?: InvitedMember): void {
        const invite: OrganizationTeamInvitation = this.users.teamConfigSnapshot.outstandingInvitations.find(i => i.id === vendor?.inviteId)
        if (!invite) {
            this.attachedInvite = undefined
            this.vendorNameOverride = undefined
            return
        }
        this.setInvite(invite)
        const request: { hiringManagerProfileId: string } = {
            hiringManagerProfileId: invite.hiringManagerProfileId,
        }
        this.hiringManager = this.clientVendorRelationships.getHiringManager(request)
        this.workOrder.hiringManagerProfileId = this.hiringManager.profileId
        this.workOrder.hiringManagerName = this.getFullName(this.hiringManager)
        this.selectedVendor = undefined
        this.workOrder.masterContractLiquidDocument = undefined
        this.workOrder.vendorNameOverride = invite.inviteeName
        this.vendorNameOverride = invite.inviteeName
        this.masterContractControl.setValue(undefined)
    }

    setNewVendor(): void {
        this.selectedVendor = undefined
        this.attachedInvite = undefined
        this.vendorNameOverride = undefined

        this.workOrder.hiringManagerProfileId = this.businessUsers.currentUser.profileId
        if (!this.workOrder.vendorInvitationInformation) {
            this.workOrder.vendorInvitationInformation = {}

            this.setSettings()
        }

        this.workflow.setDecisions({
            [CreateOrderDecision.AddNewVendor]: true,
        })

        this.workflow.setDecisions({
            [CreateOrderSteps.vendorUsage]: undefined,
        })
        this.removeOnboardingSteps()

        this.goToNext()
    }

    setSettings(organizationSettings?: OrganizationSettings): void {
        const settings$: Observable<OrganizationSettings> = !!organizationSettings
            ? of(organizationSettings)
            : this.settings?.organizationId === this.users.businessSnapshot.id
                ? of(this.settings)
                : this.organizations.getOrganizationSettings(this.users.businessSnapshot.id)

        settings$
            .pipe(
                tap(settings => {
                    this.settings = settings
                    this.hiringManager = this.businessUsers.currentUser
                }),
                filter(() => !!this.workOrder),
                tap(() => {
                    this.workOrder.hiringManagerProfileId = this.hiringManager.profileId
                    this.workOrder.hiringManagerName = this.getFullName(this.hiringManager)
                }),
                filter(settings => settings.countersigning.isEnabled
                    && !!environment.internalSettings.enableCountersigning
                    && !!this.workOrder.vendorInvitationInformation),
                tap(settings => {
                    this.workOrder.vendorInvitationInformation.hiringManagerProfileId = this.hiringManager.profileId
                    this.workOrder.vendorInvitationInformation.countersigningEnabled = true
                    this.workOrder.vendorInvitationInformation.contactSignerProfileId = settings.countersigning.defaultContractSignerProfileId
                }),
            )
            .subscribe()
    }

    hideNewVendorSteps(): void {
        this.workOrder.hiringManagerProfileId = undefined
        this.workOrder.hiringManagerName = undefined
        this.workOrder.organizationTeamInvitationId = undefined
        this.workOrder.vendorInvitationInformation = undefined
        this.ownVendorTracking = undefined
        this.invite = undefined
        this.masterContractData = undefined

        this.workflow.setDecisions({
            [CreateOrderDecision.AddNewVendor]: false,
        })

        this.workflow.setDecisions({
            [CreateOrderSteps.vendorUsage]: undefined,
        })
        this.removeOnboardingSteps()
    }

    setVendor(vendor: TeamMemberAbstract | string): void {
        const selectedVendor: TeamMemberAbstract = typeof vendor === 'string'
            ? this.existingVendors.find(v => v.teamMemberOrganization.teamMemberOrganizationId === vendor) as TeamMemberAbstract
            : vendor
        // default whoever is creating the work order as the hiring manager if no vendor is selected
        if (!selectedVendor) {
            this.selectedVendor = undefined
            this.workOrder.masterContractLiquidDocument = undefined
            this.masterContractControl.setValue(undefined)
            // hiring manager has already been set and is not the default of current user so don't override
            if (!!this.hiringManager && this.hiringManager.profileId !== this.users.userSnapshot.profileId) {
                return
            }
            this.hiringManager = this.businessUsers.currentUser
            this.workOrder.hiringManagerProfileId = this.hiringManager.profileId
            this.workOrder.hiringManagerName = this.getFullName(this.hiringManager)
            return
        }
        this.setInvitedVendor()
        const shouldCheckMasterContract: boolean = this.selectedVendor?.organizationTeamMemberId !== selectedVendor?.organizationTeamMemberId
        this.selectedVendor = selectedVendor
        this.refreshWorkOrderOrgs()
        this.setDefaultHiringManager(this.users.businessSnapshot.id, selectedVendor.teamMemberOrganization.teamMemberOrganizationId)
        if (shouldCheckMasterContract) {
            this.saveRecipient()
                .subscribe()
        }
    }

    setVendorUsage(decision: CreateOrderDecision): void {
        switch (decision) {
            case CreateOrderDecision.ClientsOwnTracking:
                this.workOrder.vendorInvitationInformation.vendorEmailMessage = undefined
                this.ownVendorTracking = true
                this.invite = undefined
                this.masterContractData = undefined
                break
            case CreateOrderDecision.StandardFlow:
                this.ownVendorTracking = undefined
                this.ownVendorTracking = false
                this.invite = {}
                break
        }

        this.removeOnboardingSteps()
    }

    setVendorValidationMessage(message: TemplateRef<any>): void {
        this.vendorValidationMessage = message
    }

    private canPickVendor(vendor: TeamMemberAbstract): boolean {
        return !vendor.hiddenTimestamp && vendor.status !== BusinessTeamMemberStates.REMOVED && this.isCompatibleVendorType(vendor.vendorType)
    }

    private getDatesInPast(): Array<{ deliverable: Deliverable, fields: Array<string> }> {
        const compare: moment.Moment = moment().startOf('day')
        return this.workOrder.deliverables.reduce((agg, deliverable) => {
            const fields: Array<string> = []
            if (moment.unix(deliverable.kickoffTimestamp).startOf('day').isBefore(compare, 'date')) {
                fields.push('Start date')
            }
            if (!!deliverable.dueTimestamp && moment.unix(deliverable.dueTimestamp).startOf('day').isBefore(compare, 'date')) {
                fields.push('Due date')
            }
            if (!!deliverable.estimatedCompletionDate && moment(deliverable.estimatedCompletionDate).startOf('day').isBefore(compare, 'date')) {
                fields.push('Estimated completion date')
            }
            if (!!deliverable.rate?.recurrenceParameters?.startTimestamp && moment.unix(deliverable.rate.recurrenceParameters.startTimestamp).startOf('day').isBefore(compare, 'date')) {
                fields.push('Invoice start date')
            }
            if (!!deliverable.rate?.recurrenceParameters?.untilTimestamp && moment.unix(deliverable.rate.recurrenceParameters.untilTimestamp).startOf('day').isBefore(compare, 'date')) {
                fields.push('Invoice end date')
            }
            // TODO: add estimated dates
            return !!fields.length ? [...agg, { deliverable, fields }] : agg
        }, [])
    }

    private getFullName(user: OrganizationMembershipAbstractMember): string {
        return `${user.firstName} ${user.lastName}`
    }

    private getFullWorkOrder(): WorkOrder {
        const workOrder: WorkOrder = {
            ...this.workOrder,
            name: this.workOrder?.name || '',
            hiringManagerProfileId: this.hiringManager?.profileId || this.workOrder?.hiringManagerProfileId,
            isDraft: true,
            deliverables: this.deliverables.value || [],
            initiatedByOrganizationId: this.business.id,
            initiatedInTimezone: this.currentTimezone,
            masterContractLiquidDocumentId: this.masterContractId,
            organizationTeamInvitationId: this.attachedInvite?.id,
            vendorNameOverride: this.vendorNameOverride,
            workerOrganization: undefined,
            workerOrganizationId: this.isWorker ? this.business.id : this.selectedVendor?.teamMemberOrganization?.teamMemberOrganizationId,
        }
        // clean up ui handling of hiring mgr
        delete workOrder.hiringManagerName
        delete workOrder.hiringManageAvatarUrl
        return workOrder
    }

    private getNextDirtyStep(): CreateOrderSteps {
        // recipient
        if (this.isWorker && this.clientControl.dirty) {
            return CreateOrderSteps.selectClientStep
        } else if (!this.isWorker && this.vendorControl.dirty) {
            return CreateOrderSteps.selectVendorStep
        }

        const firstDirtyDeliverableIndex: number = this.deliverableForms.findIndex(f => f.dirty)
        if (firstDirtyDeliverableIndex >= 0) {
            return CreateOrderSteps.servicesAndDeliverablesStep
        }
        return undefined
    }

    private goToNext(): void {
        this.workflow.activeStep.value = true
        this.workflow.nextStep()
        this.workflow.onChange()
    }

    private triggerTrackers(): void {
        if (this.workflow.activeStep.id === CreateOrderSteps.orderNameStep && this.orderType === OrderType.PurchaseOrder) {
            const payload: GATracking = {
                category: GACategory.PURCHASE_ORDERS,
                action: GAAction.PURCHASE_ORDER_NAME,
            }
            this.ga.sendEvent(payload)
        }

        if (this.workflow.activeStep.id === CreateOrderSteps.sendStep && this.orderType === OrderType.PurchaseOrder) {
            const payload: GATracking = {
                category: GACategory.PURCHASE_ORDERS,
                action: GAAction.PURCHASE_ORDER_SEND,
            }
            this.ga.sendEvent(payload)
        }

    }

    private goToNextDirtyStep(): void {
        const nextDirtyStep: CreateOrderSteps = this.getNextDirtyStep()
        if (!nextDirtyStep) {
            return
        }
        this.workflow.goToStepById(nextDirtyStep)
        this.workflow.onChange()
    }

    private handleDirtyForms(): void {
        this.showDismissConfirmation = true
        this.confirmedDismissChanges = false
        this.deliverableListRef?.cdInstance?.detectChanges()
        // listen for the confirmation to know whether to continue or return
        this.workflow.dismissConfirmation$
            .pipe(
                filter(dismiss => dismiss !== undefined),
                take(1),
                tap(dismiss => {
                    if (!!dismiss) {
                        this.resetForms()
                        this.dialogs.close()
                    }
                    this.workflow.dismissConfirmation = undefined
                    this.goToNextDirtyStep()
                }),
            )
            .subscribe()
    }

    private handleWorkflowNavigation(): Observable<WorkOrder | void> {
        this.workflow.error = undefined

        this.vendorInviteCommonService.handlePreInviteFieldSave()
        this.triggerTrackers()

        if (this.workflow.activeStep.id === InviteVendorStepId.OnboardingProcessMasterContractPeriod) {
            this.invite = {
                ...this.invite,
                ...this.workflow.form.value,
            }
        }

        if (this.isVendorInviteStep(this.workflow.activeStep)) {
            return of(undefined)
                .pipe(
                    tap(() => this.goToNext()),
                )
        }

        switch (this.workflow.activeStep.id) {
            case CreateOrderSteps.servicesAndDeliverablesStep:
                return this.updateDraft(true)
            case CreateOrderSteps.selectClientStep:
            case CreateOrderSteps.selectVendorStep:
                return this.saveRecipient(true)
            case CreateOrderSteps.sendStep:
                return this.sendWorkOrder()
            default:
                return this.savePartial(true)
        }
    }

    private removeOnboardingSteps(): void {

        const onboardingGroupIndexes: WorkflowStepIndices = this.workflow.stepMap[InviteVendorStepId.OnboardingProcessUploadMasterContract]
        if (!onboardingGroupIndexes) {
            return
        }

        const group: WorkflowStepGroup = this.modules[onboardingGroupIndexes.moduleIndex].groups[onboardingGroupIndexes.groupIndex]
        this.vendorInviteCommonService.removePreInviteSteps(group.steps)
    }

    private initializeWorkflow(stepId?: CreateOrderSteps): void {

        this.workflow.initialize(this.modules, this.stepMap)

        this.setWorkflowListeners()

        if (!!stepId) {
            this.workflow.goToStepById(stepId)
            this.workflow.onChange()
            return
        }
        this.workflow.goToMaxEnabled()
    }

    private isCompatibleVendorType(type: OrganizationTeamMembershipVendorType): boolean {
        // if type is null then treat it as valid value for work order type for backward compatibility
        return (!type && this.orderType === OrderType.WorkOrder) || this.orderTypeConfig.membershipVendorTypes.includes(type)
    }

    private isVendorInviteStep(step: WorkflowStep): boolean {
        const vendorInviteSteps: string[] = [CreateOrderSteps.vendorOwnTracking, CreateOrderSteps.vendorEmailMessage]

        return Object.values(InviteVendorStepId).some(value => value === step.id)
            || vendorInviteSteps.some(value => value === step.id)
            || this.vendorInviteCommonService.isPreInviteStep(step)
    }

    private refreshWorkOrderOrgs(): void {
        this.workOrder.clientOrganizationId = this.isWorker ? this.selectedClient?.membershipIsToOrganization?.id : this.business.id
        this.workOrder.workerOrganizationId = this.isWorker ? this.business.id : this.selectedVendor?.teamMemberOrganization?.teamMemberOrganizationId
    }

    private resetForms(): void {

        this.workflow.form.reset()

        this.selectedClient = undefined
        this.clientControl.reset()

        this.selectedVendor = undefined
        this.vendorControl.reset()
        this.vendorNameOverride = undefined
        this.attachedInvite = undefined
        this.vendorOverrideControl.reset()
    }

    private resetStorage(): void {
        localStorage.removeItem(StorageKey.purchaseOrder)
        localStorage.removeItem(StorageKey.deliverables)
        localStorage.removeItem(StorageKey.workOrder)
        localStorage.removeItem(StorageKey.deliverables)
    }

    private savePartial(next: boolean = false): Observable<WorkOrder | void> {

        if (!!this.workOrder?.id) {
            return this.updateDraft(next)
        }

        if (!!this.workOrder.name
            && !!this.workOrder.clientOrganizationId
        ) {
            this.workflow.error = undefined
            this.workflow.isLoading = true
            return this.workOrdersService.createWorkOrder(this.fullWorkOrder)
                .pipe(
                    tap(createdWorkOrder => {
                        this.workflow.isLoading = false
                        this.workOrder = {
                            ...createdWorkOrder,
                            deliverables: [],
                        }

                        window.history.pushState({}, '',
                            this.isWorker
                                ? this.orderTypeConfig.vendorOrderCreateUrlProvider(undefined, createdWorkOrder.id)
                                : this.orderTypeConfig.clientOrderCreateUrlProvider(undefined, createdWorkOrder.id),
                        )

                        this.deliverables.setValue([])
                        this.draftSaved = true
                        this.resetStorage()
                        if (!!next) {
                            this.goToNext()
                        }
                    }),
                    catchError(err => this.workflow.handleError(err)),
                )
        }
        this.saveToStorage()
        if (!!next) {
            this.goToNext()
        }
        return of(undefined)
    }

    private saveRecipient(next: boolean = false): Observable<void> {

        // if there's no relationship or the vendor is in process, no need to check the MC
        if (!this.clientVendorRelationshipId
            || (!this.isWorker && this.selectedVendor.status === BusinessTeamMemberStates.IN_PROCESS)) {
            this.goToNext()
            return of(undefined)
        }

        this.workflow.error = undefined
        this.workflow.isLoading = true
        return this.clientVendorRelationships.getMasterContract(this.clientVendorRelationshipId)
            .pipe(
                tap(() => this.workflow.isLoading = false),
                switchMap(master => {
                    this.workOrder.masterContractLiquidDocument = master
                    this.masterContractControl.setValue(master?.id)

                    // if master contract is expired, display an error
                    const masterContractExpired: boolean = !master?.documentActiveToTimestamp
                        ? false
                        : moment().startOf('day').isAfter(moment.unix(master.documentActiveToTimestamp))

                    if (!master?.id || masterContractExpired) {
                        const template: TemplateRef<any> = this.isWorker
                            ? this.clientValidationMessage
                            : this.vendorValidationMessage

                        const error: HandledError = {
                            generic: false,
                            template,
                        }

                        return this.workflow.handleError(error)
                            .pipe(
                                tap(() => this.workflow.disableNext = true), // don't let the user proceed
                            )
                    }
                    this.workflow.disableNext = false
                    if (next) {
                        return this.savePartial(next)
                    }
                    return of(undefined)
                }),
                catchError(err => this.workflow.handleError(err)),
            )
    }

    private saveToStorage(): void {
        const order: WorkOrder = { ...this.fullWorkOrder }
        const storageKey: string = this.orderType === OrderType.PurchaseOrder ? StorageKey.purchaseOrder : StorageKey.workOrder
        localStorage.setItem(storageKey, JSON.stringify(order))
    }

    private setClientsAndVendors(): void {
        this.clients = [
            ...this.teamConfig.organizationMembershipConfiguration.teamMemberships,
        ]
            .filter(c => c.onboardingComplete && !!c.onboardingCompleteTimestamp && !c.hiddenTimestamp && this.orderTypeConfig.membershipVendorTypes.includes(c.vendorType))
            .sort((a, b) => a.membershipIsToOrganization.name.localeCompare(b.membershipIsToOrganization.name))

        this.existingVendors = this.teamConfig.teamMembershipAbstract.teams
            .reduce<Array<TeamMemberAbstract>>((vendors, team) => [...vendors, ...team?.members], [])
            .filter(v => this.canPickVendor(v))
            .sort((a, b) => b.status.localeCompare(a.status) || a.teamMemberOrganization.name.localeCompare(b.teamMemberOrganization.name))

        this.invitedVendors = this.users.teamConfigSnapshot.outstandingInvitations
            .filter(item => this.isCompatibleVendorType(item?.inviteeType))
            .map(i => {
                return {
                    inviteId: i.id,
                    name: i.inviteeName,
                }
            })
            .sort((a, b) => a.name.localeCompare(b.name))

        if ((this.workOrder?.workerOrganizationId && !this.selectedVendor) || (!!this.selectedVendor && this.workOrder?.workerOrganizationId !== this.selectedVendor?.teamMemberOrganization?.teamMemberOrganizationId)) {
            this.selectedVendor = this.existingVendors.find(v => v?.teamMemberOrganization?.teamMemberOrganizationId === this.workOrder.workerOrganizationId)
        }
        if ((this.workOrder?.clientOrganizationId && !this.selectedClient) || (!!this.selectedClient && this.workOrder?.clientOrganizationId !== this.selectedClient?.id)) {
            this.selectedClient = this.clients.find(c => c.membershipIsToOrganization?.id === this.workOrder.clientOrganizationId)
        }

        if (!!this.workOrder?.organizationTeamInvitationId && !this.attachedInvite) {
            this.attachedInvite = this.users.teamConfigSnapshot.outstandingInvitations.find(i => i.id === this.workOrder.organizationTeamInvitationId)
            this.vendorNameOverride = this.attachedInvite?.inviteeName ?? this.workOrder?.vendorNameOverride
        }
        this.refreshWorkOrderOrgs()
    }

    private setDefaultHiringManager(clientId: string, vendorId: string): void {
        this.clientVendorRelationships.get(this.users.businessSnapshot.id, this.clientVendorRelationships.getId(clientId, vendorId))
            .pipe(
                map(rel => this.clientVendorRelationships.getHiringManager(rel)),
                tap(hm => {
                    const request: { hiringManagerProfileId: string } = {
                        hiringManagerProfileId: hm.profileId,
                    }
                    this.hiringManager = this.clientVendorRelationships.getHiringManager(request)
                    this.workOrder.hiringManagerProfileId = this.hiringManager.profileId
                    this.workOrder.hiringManagerName = this.getFullName(this.hiringManager)
                }),
            )
            .subscribe()
    }

    private setWorkflowListeners(): void {

        this.workflow.form.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form?.name
                    || !!form?.deliverables
                    || !!form?.[CreateOrderSteps.vendorName]
                    || !!form?.[CreateOrderSteps.vendorDescription]
                    || !!form?.[CreateOrderSteps.vendorEmail]
                    || !!form?.[CreateOrderSteps.vendorEmailMessage],
                ),
                tap(form => {
                    if (this.workflow.activeStep.id === CreateOrderSteps.orderNameStep && this.workOrder.name !== form.name) {
                        if (!!this.workOrder.deliverables[0]) {
                            this.workOrder.deliverables[0].name = form.name
                        }
                    }
                    this.workOrder.name = form.name || this.workOrder.name
                    this.workOrder.deliverables = form.deliverables || this.workOrder.deliverables

                    if (!!this.workOrder.vendorInvitationInformation) {
                        this.workOrder.vendorInvitationInformation.vendorName = form[CreateOrderSteps.vendorName] || this.workOrder.vendorInvitationInformation?.vendorName
                        if (!!this.workOrder.vendorInvitationInformation.vendorName) {
                            this.workOrder.vendorNameOverride = this.workOrder.vendorInvitationInformation.vendorName
                            this.vendorNameOverride = this.workOrder.vendorInvitationInformation.vendorName
                        }

                        this.workOrder.vendorInvitationInformation.vendorDescription = form[CreateOrderSteps.vendorDescription] || this.workOrder.vendorInvitationInformation?.vendorDescription
                        this.workOrder.vendorInvitationInformation.vendorEmail = form[CreateOrderSteps.vendorEmail] || this.workOrder.vendorInvitationInformation?.vendorEmail
                        this.workOrder.vendorInvitationInformation.vendorEmailMessage = form[CreateOrderSteps.vendorEmailMessage] || this.workOrder.vendorInvitationInformation?.vendorEmailMessage
                    }
                }),
            )
            .subscribe()

        this.workflow.formChange
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form.controls?.[CreateOrderSteps.vendorName]),
                tap(form => form.controls[CreateOrderSteps.vendorName].setValue(this.workOrder.vendorInvitationInformation?.vendorName)),
            )
            .subscribe()

        this.workflow.formChange
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form.controls?.[CreateOrderSteps.vendorDescription]),
                tap(form => form.controls[CreateOrderSteps.vendorDescription].setValue(this.workOrder.vendorInvitationInformation?.vendorDescription)),
            )
            .subscribe()

        this.workflow.formChange
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form.controls?.[CreateOrderSteps.vendorEmail]),
                tap(form => form.controls[CreateOrderSteps.vendorEmail].setValue(this.workOrder.vendorInvitationInformation?.vendorEmail)),
            )
            .subscribe()

        this.workflow.formChange
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form.controls?.[CreateOrderSteps.vendorEmailMessage]),
                tap(form => form.controls[CreateOrderSteps.vendorEmailMessage].setValue(this.workOrder.vendorInvitationInformation?.vendorEmailMessage)),
            )
            .subscribe()

        this.workflow.formChange
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(form => !!form.controls?.name),
                tap(form => form.controls.name.setValue(this.workOrder?.name)),
            )
            .subscribe()

        this.workflow.next
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap(() => this.handleWorkflowNavigation()),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()

        this.workflow.previous
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(() => {
                    this.workflow.previousStep()
                    this.workflow.onChange()
                }),
                catchError(error => this.dialogs.error(error)),
            )
            .subscribe()
    }

    private sortDeliverables(deliverables: Array<Deliverable>): Array<Deliverable> {
        return [...deliverables].sort((a, b) => a.displayOrder - b.displayOrder)
    }

    private updateDraft(next: boolean = false): Observable<WorkOrder | void> {
        this.workflow.isLoading = true
        const partial: WorkOrder = this.getFullWorkOrder()
        partial.deliverables = partial.deliverables.filter(t => !!t.id)
        return this.workOrdersService.updateWorkOrder(partial)
            .pipe(
                take(1),
                tap(workOrder => {
                    this.workflow.isLoading = false
                    this.deliverables.setValue(this.sortDeliverables(this.deliverables.value))
                    this.workOrder = {
                        ...workOrder,
                        deliverables: this.deliverables.value,
                    }
                    this.resetStorage()
                    if (next) {
                        this.goToNext()
                    }
                }),
                catchError(err => this.workflow.handleError(err)),
            )
    }

    private sendWorkOrder(): Observable<WorkOrder> {

        // save the WO, then finalize/send it before going to the next step
        return this.savePartial(false)
            .pipe(
                tap(() => this.workflow.isLoading = true),
                switchMap(() => this.finalize()),
                tap(() => {
                    this.workflow.isLoading = false
                    this.goToNext()
                }),
            )
    }

    private finalize(): Observable<WorkOrder> {

        const onboardingProcess: OnboardingProcess = !!this.invite?.onboardingProcess
            ? this.invite?.onboardingProcess
            : this.teamConfig.onboardingProcesses
                .find(op => op.steps.some(s => s.stepType === OnboardingStepType.genericMasterContract))

        const request$: Observable<OnboardingProcess> = !onboardingProcess && this.ownVendorTracking !== undefined
            ? this.onboardings.getNewDefaultOnboardingProcess(this.users.userSnapshot.currentOrganization.id, OnboardingTemplateType.GenericMasterContract)
            : of(onboardingProcess)

        return request$
            .pipe(
                switchMap(op => this.workOrdersService.finalizeDraftWorkOrder({
                    workOrderId: this.workOrder.id,
                    ownVendorTracking: this.ownVendorTracking,
                    onboardingProcess: op,
                })),
                take(1),
                switchMap(workOrder => {

                    if (!this.workOrder.vendorInvitationInformation?.id
                        || this.ownVendorTracking !== false
                        || !this.invite.masterContract) {

                        return of(workOrder)
                    }

                    return this.organizations.getOrganizationTeamInvitation(this.workOrder.vendorInvitationInformation.id)
                        .pipe(
                            map(invitation => invitation.onboardingProcess.steps.find(step => step.isMasterContractDocument).id),
                            switchMap(stepId => this.onboardings.submitDocumentUpload(
                                stepId,
                                this.invite.masterContract,
                                this.masterContractData,
                            )
                                .pipe(
                                    map(document => ({ document, stepId })),
                                ),
                            ),
                            switchMap(({ document, stepId }) => this.onboardings.updateOnboardingStepDocument(
                                stepId,
                                document.id,
                                {
                                    documentActiveFrom: this.invite.startDate,
                                    documentActiveTo: this.invite.endDate,
                                    scope: LiquidDocumentUpdateRequestScope.DateUpdate,
                                },
                            )),
                            map(() => workOrder),
                        )
                }),
            )
    }

}
