<app-dialog-template-small
    *ngIf="ready"
    [title]="title"
>
    <p
        *ngIf="!!header"
        class="dialog-content mtv-10 mbv-10"
    >
        {{header}}
    </p>
    <div class="dialog-input">
        <mat-form-field class="w-100">
            <mat-label>{{label}}</mat-label>
            <textarea
                #messageEditor
                class="text-input textarea"
                matInput
                matTextareaAutosize
                matAutosizeMinRows=10
                matAutosizeMaxRows=15
                [(ngModel)]="messageEditorMessage"
                required
            ></textarea>
        </mat-form-field>
        <mat-error *ngIf="messageEditorMessage && messageEditorMessage.length < 5">Your message must be at least 5
            characters.</mat-error>
    </div>
    <button
        mat-flat-button
        color="primary"
        (click)="sendMessage()"
        [disabled]="!messageEditorMessage || messageEditorMessage.length < 5"
        class="dialog-button"
    >
        {{buttonText}}
    </button>
</app-dialog-template-small>